<template>
    <section>
        <modal ref="modalDetalleEntrega" titulo="Detalle de la entrega" tamano="modal-xl" icon="aprobar" no-aceptar no-cancelar>
            <div class="row mx-0 justify-content-around mb-3">
                <div class="col-auto d-middle text-general border p-2 br-10 box-green d-middle-center">
                    {{ separadorNumero(_.get(datos, 'valor', '0')) }}
                </div>
                <div class="col-auto d-middle text-general border box-gray p-2 br-10 d-middle-center">
                    {{ _.get(datos, 'codigo') }}
                </div>
            </div>
            <div class="row mx-0 justify-content-between mb-3">
                <div class="col-auto d-middle text-general ">
                    Seguimiento de la entrega
                </div>
                <div v-if="_.get(datos, 'estado') <= 2 && _.get(datos, 'tipo', false) == 1" class="bg-general py-1 px-3 br-5 text-white cr-pointer" @click="modalVerificado()">
                    Anular entrega
                </div>
            </div>
            <div class="row mx-0 p-2">
                <div class="col-11 d-middle">
                    <i class="icon-entrega-dinero text-success f-17 m-2" />
                    <img :src="_.get(datos, 'logo_mini')" width="36px" height="36px" class="obj-cover rounded-circle m-2" />
                    <div class="px-0 text-general">
                        <p class="f-600">Entrega en {{ datos.tipo == 1 ? 'Efectivo': (datos.tipo == 2 ? 'Transferencia' : 'Pago en línea') }}</p>
                        <p class="f-14">{{ formatearFecha(_.get(datos, 'created_at'), 'D MMM Y hh:mm a') }}</p>
                    </div>
                    <div class="col-8">
                        {{ _.get(datos, 'comentario') }}
                        <template v-if="datos.tipo == 3">
                            <div class="row p-2">
                                <div class="col">
                                    <span class="f-600 text-general">Pago</span>
                                </div>
                                <div class="col">
                                    <span :class="estado(datos.pasarela_payu.state_pol).color" class="bg-success br-12 p-2 text-white">
                                        {{ estado(datos.pasarela_payu.state_pol).name }}
                                    </span>
                                </div>
                            </div>
                            <div class="row p-2">
                                <div class="col">
                                    <span class="f-600 text-general">Referencia</span>
                                </div>
                                <div class="col">
                                    <span class="">
                                        {{ _.get(datos, 'pasarela_payu.transaction_id') }}
                                    </span>
                                </div>
                            </div>
                            <div class="row p-2">
                                <div class="col">
                                    <span class="f-600 text-general">Medio de pago</span>
                                </div>
                                <div class="col">
                                    <span class="">
                                        {{ _.get(datos, 'pasarela_payu.payment_method_name') }}
                                    </span>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="row mx-0 p-2">
                        <div class="col-10 d-middle">
                            <i class="icon-entrega-dinero text-success f-17 m-2" />
                            <img :src="_.get(datos, 'logo_mini')" width="36px" height="36px" class="obj-cover rounded-circle m-2" />
                            <div class="px-0 text-general">
                                <p class="f-600">Entrega en {{ _.get(datos, 'estado') == 1 ? 'Efectivo' : 'Transferencia' }}</p>
                                <p class="f-14">{{ formatearFecha(_.get(datos, 'created_at'), 'D MMM Y hh:mm a') }}</p>
                            </div>
                            <div class="col-8">
                                {{ _.get(datos, 'comentario') }}
                            </div>
                        </div>
                    </div>
                    <div v-if="_.get(datos, 'fecha_recibio')" class="row mx-0 p-2">
                        <div class="col-10 d-middle">
                            <i class="icon-entrega-dinero text-success f-17 m-2" />
                            <img :src="_.get(datos, 'delivery_foto')" width="36px" height="36px" class="obj-cover rounded-circle m-2" />
                            <div class="px-0 text-general">
                                <p class="f-600">Recibido por {{ _.get(datos, 'delivery_nombre') }}</p>
                                <p class="f-14">{{ formatearFecha(_.get(datos, 'fecha_recibio'), 'D MMM Y hh:mm a') }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="_.get(datos, 'estado') >= 3" class="row mx-0 p-2">
                        <div class="col-10 d-middle">
                            <i class="icon-entrega-dinero text-success f-17 m-2" />
                            <img :src="_.get(datos, 'estado') == 4 ? _.get(datos, 'logo_mini') : '/img/icons/bell.png'" width="36px" height="36px" class="obj-cover rounded-circle m-2" />
                            <div class="px-0 text-general">
                                <p class="f-600">{{ titulo }}</p>
                                <p class="f-14">{{ formatearFecha(_.get(datos, 'fecha_confirmo'), 'D MMM Y hh:mm a') }}</p>
                            </div>
                            <div class="col-8">
                                {{ _.get(datos, 'anulo_comentario') }}
                            </div>
                        </div>
                    </div>
                    <template v-if="false">
                        <div v-for="(sg, s) in seguimiento" :key="s" class="row mx-0 mb-2" style="min-height:60px;">
                            <div class="col d-flex">
                                <div class="col-1 px-2 d-flex flex-column align-items-center">
                                    <i :class="`${iconoSeguimiento(sg.estado)}`" />
                                    <div class="br-5" style="background-color:#E6E6E6;width:2px;height:100%;" />
                                </div>
                                <div class="col  d-flex">
                                    <img :src="sg.img" width="36" height="36" class="rounded-circle border" />
                                    <div class="col pl-3 pr-0 text-general f-15">
                                        <p> <b>{{ sg.titulo }}</b> </p>
                                        <p>{{ sg.fecha }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col f-14 text-general">
                                {{ sg.detalle }}
                                <div v-if="sg.url_transaccion" class="row mx-0 mt-2">
                                    <img :src="sg.url_transaccion" width="232" height="232" class="obj-cover border br-8" @click="detalleImage(sg.url_transaccion)" />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </modal>
        <modal-verificado ref="modalVerificado" @saved="closed()" />
        <modal-detalle-image ref="modalDetalleImage" :url="url" @volver="volver" />
    </section>
</template>

<script>
import entregasDineros from '~/services/almacen/entregas_dineros'
const payuEstado = {
    4: { name: 'Aprobado', color: 'bg-success' },
    5: { name: 'Expirada', color: 'bg-danger' },
    6: { name: 'Declinada', color: 'bg-danger' },
    7: { name: 'Pendiente', color: 'bg-warning' },
    104: { name: 'Error', color: 'bg-danger' },
}

export default {
    components: {
        modalVerificado: () => import('./modalVerificado.vue'),
        modalDetalleImage: () => import('./modalDetalleImage')
    },

    data(){
        return {
            url:'',
            datos: {},
            id: '',
            seguimiento: [
                {
                    titulo: 'Entrega en efectivo',
                    estado: 1,
                    detalle: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea',
                    fecha: '10 Jun. 2020 - 03:26 p.m.',
                    img: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg',
                    url_transaccion: 'https://www.easygameitems.com/egi19wp/wp-content/uploads/2020/10/egi-mp-nequi.png'
                },
                {
                    titulo: 'Recibido por Daniel Carrillo',
                    estado: 2,
                    fecha: '10 Jun. 2020 - 03:26 p.m.',
                    img: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg'
                },
                {
                    titulo: 'Registrado por tesorería',
                    estado: 3,
                    detalle: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea',
                    fecha: '10 Jun. 2020 - 03:26 p.m.',
                    img: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg'
                },
                {
                    titulo: 'Anulado',
                    estado: 4,
                    fecha: '10 Jun. 2020 - 03:26 p.m.',
                    img: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg'
                },
                {
                    titulo: 'Anulado por tesorería',
                    estado: 4,
                    detalle: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea',
                    fecha: '10 Jun. 2020 - 03:26 p.m.',
                    img: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg'
                }
            ]
        }
    },
    computed: {
        titulo(){
            if(_.get(this.datos, 'estado') == 3){
                return 'Registrado por tesorería'
            }

            if(_.get(this.datos, 'estado') == 4){
                return 'Anulado'
            }

            if(_.get(this.datos, 'estado') == 5){
                return 'Anulado por tesorería'
            }
            return ''
        }
    },
    methods: {
        estado(tipo){
            return payuEstado[tipo] ?? payuEstado[104]
        },
        async toggle(id){
            this.id = id
            await this.detalle()
            this.$refs.modalDetalleEntrega.toggle();
        },

        async detalle(){
            try {
                const { data } = await entregasDineros.detalle(this.id)
                this.datos = data.data
            } catch (error){
                this.error_catch(error)
            }
        },
        iconoSeguimiento(estado){
            switch (estado){
            case 1:
                return 'icon-comision text-general f-17';
            case 2:
                return 'icon-entrega-dinero text-success f-18';
            case 3:
                return 'icon-account-check text-success f-22';
            case 4:
                return 'icon-playlist-remove  text-general f-22';
            }
        },
        detalleImage(url_image){
            this.url = url_image;
            this.$refs.modalDetalleEntrega.toggle();
            this.$refs.modalDetalleImage.toggle();
        },
        modalVerificado(){
            this.$refs.modalDetalleEntrega.toggle();
            this.$refs.modalVerificado.toggle(this.id);
        },
        closed(){
            this.$emit('closed')
            this.$refs.modalVerificado.toggle()

        },
        volver(){
            this.$refs.modalDetalleImage.toggle();
            this.$refs.modalDetalleEntrega.toggle();
        }

    }
}
</script>
<style scoped>
.box-green {
    border: 1px solid #28D07B !important;
    background-color: #e9faf2 !important;
    /* width: 250px; */
}
.box-gray {
    border: 1px solid #DBDBDB !important;
    background-color: #F5F5F5 !important;
    width: 250px;
}
</style>
